<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 按钮操作区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
              <el-select
                  size="small"
                  v-model="selectValue"
                  filterable
                  clearable
                  @change="searchInput"
                  placeholder="请选择商户">
                <el-option
                    v-for="item in selectOptions"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId" />
              </el-select>
            <el-button type="primary" size="small"  style="margin-left: 5px" @click="searchInput">搜索 </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
<!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column v-for="(item, index) in tableColumnData" :key="index" :prop="item.prop" :label="item.label" align="center"></el-table-column>
<!--          <el-table-column label="操作" align="center">-->
<!--            <template #default="{row}">-->
<!--              <el-button type="text" @click="editClick(row)">日统计</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>

    <!-- 统计  -->
    <el-dialog
      v-model="visible"
      width="40%"
      :title="titleName"
      :close-on-click-modal="false"
      @closed="resetForm"
    >
      <div class="date-picker-class">
        <el-date-picker
            v-model="formData.alarTime"
            unlink-panels
            type="daterange"
            size="small"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            style="display: flex;"
            :shortcuts="datePickerShortcut"
            @change="datePickerSearchInput"
        />
      </div>


      <!-- 表格区域 -->
        <el-table
            ref="tableRef"
            :data="statisticalTableData"
            style="width: 100%"
            max-height="430px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              v-for="(item, index) in statisticalTableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
          ></el-table-column>
        </el-table>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
            :total="statisticalTotal"
            @pageChange="handleStatisticalSizeChange"
            @currentChange="handleStatisticalCurrentChange"
        ></page-nation>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import datePickerComposition from "../../../common/datePickerComposition";
import {getAgencyApi, getOrgApi, stationDayApi} from "@/api/synthesisAnalysis/deviceAnalysis";
export default {
  name: "BranchAnalysis",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const { datePickerShortcut } = datePickerComposition()
    const data = reactive({
      selectValue: null, //搜索内容（用户名）
      selectOptions:[],
      searchContent: null, //搜索日志内容
      tableData: [], // 表格数据
      tableColumnData: [
        {
          prop: 'stationName',
          label: '充电站名称'
        }, {
          prop: 'totalPractical',
          label: '总金额(元)'
        }, {
          prop: 'totalElectricity',
          label: '总电度数(度)'
        }, {
          prop: 'totalDuration',
          label: '总时长(小时)'
        }, {
          prop: 'totalIndent',
          label: '订单数(次)'
        }, {
          prop: 'maxPractical',
          label: '最大金额(元)'
        }, {
          prop: 'maxDuration',
          label: '最大时长(小时)'
        }],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      visible: false,
      titleName: '日统计',
      statisticalTableColumnData: [
        {
          prop: 'totalIndent',
          label: '订单次数'
        },{
          prop: 'stationName',
          label: '充电站名称'
        }, {
          prop: 'totalPractical',
          label: '总金额(元)'
        },{
          prop: 'maxPractical',
          label: '最大金额(元)'
        }, {
          prop: 'totalElectricity',
          label: '总电度数(度)'
        }, {
          prop: 'totalDuration',
          label: '总时长(小时)'
        }, {
          prop: 'maxDuration',
          label: '最大时长(小时)'
        }, {
          prop: 'time',
          label: '日期'
        }
      ],
      statisticalTableData: [],
      // 统计页面分页
      statisticalTotal: 0,
      statisticalPageSize: 10,
      statisticalCurrentPage: 1,
      // 存放表格传到编辑页的参数
      statisticalRow: {}
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      methods.getOrg()
      methods.getAgency()
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.getOrg()
    }, 500);
    const datePickerSearchInput = debounces(() => {
      methods.getTail()
    }, 500);

    const methods = {
      // 查询旗下代理商户信息
      getAgency() {
        requestApi(() => {
          return getAgencyApi({
            merchantId:data.userInfo.merchantId,
            current: data.currentPage,
            size: data.pageSize,
          })
        },(res) => {
          data.selectOptions = res.data
        })
      },
      // 分页查询
      getOrg() {
        console.log(data.selectValue,'data.selectValue')
        let params = {
          current: data.currentPage,
          size: data.pageSize,
          merchantId: (data.selectValue == null || data.selectValue == '') ? data.userInfo.merchantId : data.selectValue,
        }
        requestApi(
            () => {
              return getOrgApi(params)
            },
            (res) => {
              data.total = res.data.total
              data.pageSize = res.data.size
              data.currentPage = res.data.current
              data.tableData = res.data.records.map((it) => {
                return {
                  stationId: it.stationId,
                  stationName: it.stationName,
                  totalPractical: (it.totalPractical / 100).toFixed(2),
                  totalElectricity: (it.totalElectricity / 1000),
                  totalDuration: (it.totalDuration / 60).toFixed(2),
                  totalIndent: it.totalIndent,
                  maxPractical:( it.maxPractical / 100).toFixed(2),
                  maxDuration: (it.maxDuration / 60).toFixed(2),
                  time: it.time
                }
              })
            }
        )
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val
        methods.getOrg()
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val
        methods.getOrg()
      },
      // 统计按钮
      editClick(row) {
        console.log(row);
        data.visible = true
        data.statisticalRow = {
          stationName: row.stationName,
          stationId: row.stationId
        }
        methods.getTail()
      },
      // 统计分页
      getTail() {
        requestApi(
            () => {
              const params = {
                current: data.statisticalCurrentPage,
                size: data.statisticalPageSize,
                stationId: data.statisticalRow.stationId,
                startTime: data.formData.alarTime === null ? '' : data.formData.alarTime[0],
                endTime: data.formData.alarTime === null ? '' : data.formData.alarTime[1],
                stationName: data.statisticalRow.stationName,
              }
              return stationDayApi(params)
            },
            (res) => {
              console.log(res)
              data.statisticalTableData = res.data.records.map((it) => {
                return {
                  stationName: it.stationName,
                  totalPractical: (it.totalPractical / 100).toFixed(2),
                  totalElectricity: (it.totalElectricity / 1000),
                  totalDuration: (it.totalDuration / 60).toFixed(2),
                  totalIndent: it.totalIndent,
                  maxPractical: (it.maxPractical / 100).toFixed(2),
                  maxDuration: (it.maxDuration / 60).toFixed(2),
                  time: it.time
                }
              })
              data.statisticalTotal = res.data.total
              data.statisticalPageSize = res.data.size
              data.statisticalCurrentPage = res.data.current
            }
        )
      },
      // 统计表格分页 改变每页数量时触发
      handleStatisticalSizeChange(val) {
        proxy.statisticalPageSize = val
        methods.getTail()
      },
      // 统计表格分页 改变页数时触发
      handleStatisticalCurrentChange(val) {
        proxy.statisticalCurrentPage = val
        methods.getTail()
      },
      resetForm() {
        data.visible = false
        data.statisticalRow = {}
        data.formData.alarTime = []
      },


    };
    return {
      ...toRefs(data),
      searchInput,
      datePickerSearchInput,
      ...methods,
      datePickerShortcut
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}

/deep/.el-dialog__body {
  padding-top: 0;
}
.date-picker-class {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
